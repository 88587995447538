
const Date = () => {

    return <section className="date-section">
        <div className="date-container">
            <div className="date" id="date">Senast Uppdaterat: 2023-03-08 </div>
        </div>
    </section>;
}



export default Date;